@import url(https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.checkbox {
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    color: #000000;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.checkbox.disabled {
    cursor: default;
}

.checkbox svg {
    min-width: 1.25rem;
    margin-right: 0.496875rem
}


.notification .icon {
    margin-left: 1.0625rem;
}

.notification .content {
    margin-left: 1.0625rem;
    color: #FFFFFF;
    width: 15.625rem;
    flex-grow: 1;

}

.notification .content .message {
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.375rem;
}

.notification .content .status {
    font-family: 'Asap', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.1875rem;
}

.notification .view {
    font-family: 'Asap', sans-serif;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: #FFFFFF;

    border-left: 1.11935px solid #FFFFFF;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    height: 2rem;

    display: flex;
    align-items: center;
}

@keyframes notificationFadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    100% {
        opacity: 1;
        display: flex;
    }
}

@keyframes notificationFadeOut {
    0% {
        opacity: 1;
        display: flex;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

.notification.hide {
    transform: translateY(100%);
    opacity: 0;
}

.notification.show {
    transform: translateY(0);
    opacity: 1;
}



.notifications-container {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.notification {
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.5s ease, opacity 0.5s ease;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}



.samplestatus {
    display: flex;
    align-items: center;
}

.samplestatus .right {
    display: flex;
    flex-direction: column;
    margin-left: 0.625rem;
}

.samplestatus .update {
    font-family: Inter;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: #767676;
}

.samplestatus p span {
    color: #767676;
}
.courier-route {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--white);
    border: 1px solid var(--gray3);
    box-sizing: border-box;
    border-radius: 0.625rem;
    width: 20.6875rem;
    height: 7.5625rem;
}

.courier-route .holder {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.courier-route .holder .left {
    transform: translateX(-1.1rem);
}

.courier-route .holder .right {
    text-align: right;
    transform: translateX(1.1rem);
}

.courier-route .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.8125rem;
    width: 15.9375rem;
}

.courier-route .icons div.center {
    margin: auto 5px;
    display: flex;
    align-items: center;
}

.courier-route .place {
    font-family: 'Inter', sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: var(--gray);
    margin-bottom: 2px;
}

.courier-route .site {
    font-family: 'Inter', sans-serif;;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
}

.navbar {
    list-style: none;
    width: 100%;
    padding-inline-start: 0;
    display: flex;
    margin: 4px 0;
}
.navbar li.pointer {
    cursor: pointer;
}

.navbar li {
    background: var(--white);
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: var(--blue);
    padding: 1rem 1.4375rem;
    border-radius: 4px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 5.4375rem;
    margin-right: 3px;
}

.navbar li.active, .navbar li.pointer:hover {
    background: var(--blue);
    color: var(--white);
}

.navbar li span.tablabel.margin {
    margin-right: 0.5625rem;
}

.navbar li span.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--red);
    border-radius: 4px;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    text-align: center;
    color: #FFFFFF;
    padding: 4px;
    min-width: 1rem;
    height: 1rem;
}
.sidebar {
    background-color: #022229;
    width: 5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar hr {
    height: 1.5px;
    background-color: #107479;
    border: none;
    margin-top: 0;
    margin-bottom: 2rem;
    width: 80%;
}

.sidebar .items {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar .items li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5625rem;
    height: 3.3125rem;
    border-radius: 5px;
    cursor: pointer;
}

.sidebar .items li.active, .sidebar .items li:hover {
    background: rgba(119, 204, 208, 0.2);
}


.sidebar .logodiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.sidebar .logo {
    margin-top: 1.75rem;
    margin-bottom: 1.4375rem;
}

.sidebar .bottom {
    margin-top: auto;
    width: 100%;
}
.container {
    display: flex;
    height: 100vh;
}

.container-full {
    display: flex;
}

.page {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.page .content {
    padding: 1.2rem 1.9rem;
    height: calc(100% - 2.4rem);
}

.page .content .title {
    margin-top: 0;
    margin-bottom: 1.75rem;
}

.page .center {
    text-align: center;
    padding: 1rem;
}

.content-full {
    width: 100%;
    height: 100%;
    position: relative;
}

.content-full .cancel {
    position: absolute;
    right: 2.25rem;
    top: 2rem;
}

.content-full .closeBtn {
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.sample_id {
    color: var(--gray2);
}

.content-full hr {
    background-color: #E0E0E0;
    height: 1px;
    width: 95%;
    border: none;
    margin-top: 0;
    margin-bottom: 0;
}

.page .content-full .title {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.content-full .full-content {
    width: 32.875rem;
    margin: 0 auto;
}

.fixed-header {
    position: fixed;
    width: 100%;
    background: white;
    z-index: 300;
}

.fixed-header + .full-content {
    padding-top: 8rem;
}


.pb-3{
    padding-bottom: 220px;
}

.pt-1{
    padding-top: 38px;
}
.message-banner{
    padding: 8px;

}

.message-banner .banner-content .message  {
    text-align: center;
    padding: 0.8125rem;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    color: #2C2A2E;
}
.message-banner .banner-content .badge {
    text-align: center;
    padding: 0 11px;
    font-family: 'Inter', sans-serif;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    height: 28px;
    background: #309A9F;
    border-radius: 18px;
}
.message-banner .banner-content{
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select {
    border-radius: 4px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid #5E5E5E;
    background-color: var(--gray4);
    padding: 1rem 1.1875rem;
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    cursor: text;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 3.225rem;
    font-weight: 400;
}

.select .chevron {
    position: absolute;
    right: 1.5625rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.selectarea {
    position: relative;
    width: 100%;
}

.selectarea .items {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: #F5F5F5;
    max-height: 16.4rem;
    overflow-y: auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.selectarea .items .item {
    cursor: pointer;
    padding: 1rem;
}

.selectarea .items .item:hover, .selectarea .items .item.active {
    background: var(--gray3);
}

.selectarea .items::-webkit-scrollbar {
    width: 3.64px;
}

.selectarea .items::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 20px;
}

.selectarea .items::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.select .select_text {
    border: none;
    outline: none;
    padding: 0;
}

p.select-error {
    color: #e44f4f;
    margin-top: 2px;
}

.selectarea .placeholder{
    color: #00000080;
}
.row {
    width: 100%;
    background: var(--white);
    padding: 0;
    margin-bottom: 3px;
    display: flex;
    flex-direction: column;
}

.row.border-0 {
    border-radius: 0.5rem;
}

.row.border-1 {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.row.border-2 {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.row.full .rowcontent {
    min-height: 48vh;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #828282;
}

.row.pointer {
    cursor: pointer;
}

.row .rowcontent {
    padding: 0.9375rem 1.125rem;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
}

.row.selected {
    background-color: #E4F5F6;
    border-bottom: 3px solid #CAE9EB;
}

.sfcontainer {
    position: relative;   
    min-width: 20.375rem; 
}

.sfcontainer .sficon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.searchfield {
    background: var(--gray4);
    border-radius: 4px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2.9375rem;
    padding-right: 1rem;
    border: none;

    color: #828282;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 2rem;
}
.pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 0;
    list-style: none;
    margin: 0;
    padding: 0;
}

.pagination .stat {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    letter-spacing: 0.35px;
    color: #000000;
}

.pagination li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination li.active {
    cursor: pointer;
}

.pagination li:not(last-child){
    margin-right: 1.875rem;
}
.modal-shadow {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 300;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.modal {
    margin-top: 0.75rem;
    padding: 1.375rem 1.625rem 1.375rem 2.5rem;
    width: 38.25rem;
    background: #FFFFFF;
    border-radius: 0.625rem;
}

.modal.show {
    transform: translateY(-100%);
    opacity: 0;
    animation: showModal 0.3s;
    animation-fill-mode: forwards;
}

.modal.hide {
    animation: hideModal 0.3s;
    animation-fill-mode: forwards;
}

@keyframes showModal {
    0% {
        transform: translateY(-100%);
        opacity: 0;  
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes hideModal {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;  
    }
}

.modal .modal-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 1.625rem;
    line-height: 1.875rem;
    color: #000000;
    margin-bottom: 1.6875rem;
}

.modal .modal-title svg {
    cursor: pointer;
}

.modal .modal-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.custodylogs {
    font-family: 'Inter', sans-serif;
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
}

.custodylogs li:not(:first-child) {
    margin-top: 1.375rem;
}

.custodylogs li {
    display: block;
    width: 100%;
    border-bottom: 2px solid #E0E0E0;
}

.custodylogs li div {
    font-size: 0.875rem;
    line-height: 1.0625rem;
}

.custodylogs li div.status {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 0.875rem;
}

.custodylogs li div.value {
    font-weight: 500;
    color: #000000;
    margin-bottom: 0.625rem;
}

.custodylogs li div.date {
    font-style: italic;
    font-weight: 500;
    color: #000000;
    margin-bottom: 1.625rem;
}
.toggleswitch {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggleswitch svg {
    cursor: pointer;
    margin-right: 0.6875rem;
}

.toggleswitch span {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
}
.fullname {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fullname input {
    width: 48% !important;
}
.dlbtn {
    margin-right: 8px;
}

.sortable {
    margin-left: 4px;
    position: relative;
}

.sortable svg {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sortable .popmenu {
    position: absolute;
    top: 13px;
    padding-inline-start: 0;
    list-style: none;
    min-width: 188px;
    background: #F5F5F5;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    animation: fade 0.3s;
    transform: translateX(-50%);
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sortable .popmenu li {
    cursor: pointer;
    padding: 15px 8px;
    background: #F5F5F5;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sortable .popmenu li:hover {
    background: var(--blue);
    color: var(--white);
}

.sortable .popmenu li span {
    white-space: nowrap;
    margin-right: 1rem;
}

.loginpage {
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.loginbox {
    background: #FFFFFF;
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    width: 31rem;
}

.loginbox .topborder {
    max-width: 100%;
}

.loginbox .padding {
    padding: 3.4375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loginbox hr {
    height: 2px;
    width: 100%;
    background-color: #E0E0E0;
    border: none;
    margin-bottom: 1.8125rem;
    margin-top: 0;
}

.loginbox p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: var(--gray);
    width: 100%;
}

.loginbox .signin {
    margin-bottom: 3.375rem;
}

.loginbox form {
    width: 100%;
}

.loginbox .field {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.8125rem;
}

.loginbox input {
    width: 100%;
}

.loginbox .button {
    display: flex;
    justify-content: center;
    margin-bottom: 3.75rem;
}

.loginpage .loginlogo {
    margin-bottom: 2rem;
}
.csform {
    width: 27.875rem;
}

.field {
    margin-bottom: 3.125rem;
}

label.label {
    display: block;
    width: 100%;
    margin-bottom: 0.8125rem;
    font-family: Inter;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #3A3A3A;
}
.csform input {
    width: 100%;
}

.csform .buttons {
    display: flex;
    margin-bottom: 14rem;
}

.review.banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}

.review.banner .bannercontent {
    width: 32.875rem;
    display: flex;
    flex-direction: row;
}

.remindercard {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.375rem 1.1875rem;
    margin-top: 2.1875rem;
}

.remindercard .bold, .field .bold {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
}

.field .bold {
    font-size: 15px;
}

hr.full {
    height: 1px;
    background-color: var(--gray3);
    border: none;
    width: 100%;
}

hr.full1 {
    margin-top: 2.125rem;
    margin-bottom: 2.6875rem;
}

hr.full2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.remindercard .text, .field .text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: var(--gray);
    margin-bottom: 6px;
}

.remindercard .bold.mb, .field .bold.mb {
    margin-bottom: 2.0625rem;
}

.reviewAlert {
    display: flex;
}

.reviewAlert svg {
    min-width: 1.5rem;
    margin-right: 0.6875rem;
}

span.required {
    color: #E44F4F;
}

.newrow{
    display: flex;
    justify-content: start;
}
.newrow .col.col-12{
    width: 100%;
}

.newrow .col.col-6{
    width: 50%;
}
.page .tophead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.page .tophead .resources-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    font-family: 'Asap', sans-serif;
    font-weight: 600;
}

.page .tophead .resources-link a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page .tophead .resources-link .divider {
    border-left: #E0E0E0 2px solid;
    padding-left: 18px;

}

.page .tophead h2.title {
    margin-bottom: 0;
    padding-right: 19px;
}

.samples .header .rowcontent {
    padding-top: 8px;
}

.samples .rowcontent div.th, .samples .rowcontent div.td {
    width: calc(100% / 5);
}

.samples .rowcontent div.th.w-100, .samples .rowcontent div.td.w-100  {
    width: 100px;
}

.samples .rowcontent div.th.narrow, .samples .rowcontent div.td.narrow {
    width: calc(100% / 10);
}

.samples .rowcontent div.th:first-of-type, .samples .rowcontent div.td:first-of-type {
    padding-left: 2.125rem;
}

.samples .rowcontent div.td:first-of-type {
    font-weight: bold;
}

.samples .rowcontent div.td {
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.samples .rowcontent div.th {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #2C2A2E;
    padding-right: 1rem;
    display: flex;
    align-items: center;
}

.samples .rowcontent div.th.pointer {
    cursor: pointer;
}

.samples .hascheck .rowcontent div.th, .samples .hascheck .rowcontent div.td {
    width: calc(100% / 7);
}

.paginator .rowcontent {
    justify-content: flex-end;
    padding: 0.75rem 0;
}

.samples .text-center {
    text-align: center;
    width: 100%;
}

.samples .rows {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
}

.samples .row.full {
    flex-grow: 1;
}

.samples .rows.rmb {
    margin-bottom: 70px;
}

.samples .rows::-webkit-scrollbar {
    width: 3.64px;
}

.samples .rows::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 20px;
}

.samples .rows::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.samples .last {
    flex-grow: 1;
}


.sampleview .content {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
}

.sampleview .sampleviewhead {
    padding: 2.125rem 1.9375rem 0 2.125rem;
    background-color: var(--white);
    height: 8.5rem;
}

.sampleviewheadtop {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sampleviewheadtop .buttoncontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.sampleviewheadtop .buttoncontainer button:not(:last-child) {
    margin-right: 0.5rem;
}

.sampleviewhead hr {
    border: none;
    height: 1.5px;
    background-color: #E0E0E0;
    margin-top: 1.4375rem;
    margin-bottom: 0.875rem;
}

.sampleviewhead .summary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.sampleviewhead .summary .date {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #4E4E4E;
    display: flex;
    align-items: center;
}

.sampleviewcontent {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    height: calc(100% - 15.5rem);
}

.sampleviewcontent .title {
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    color: #000000;
}

.sampleviewcontent .col {
    background: #FFFFFF;
    border-radius: 0.5rem;
    padding: 1.4375rem;
    padding-right: 4px;
    height: 100%;
}

.sampleviewcontent .left {
    width: 80%;
    margin-right: 1.4375rem;
}

.sampleviewcontent .right {
    width: 20%;
}

.withscroll {
    overflow-y: auto;
    padding-right: 1.4375rem;
    height: 85%;
}

.right .withscroll {
    height: 90%;
}

.withscroll::-webkit-scrollbar, .sampleviewcontent .col::-webkit-scrollbar {
    width: 3.64px;
}

.withscroll::-webkit-scrollbar-track, .sampleviewcontent .col::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 20px;
}

.withscroll::-webkit-scrollbar-thumb, .sampleviewcontent .col::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.row3col {
    display: flex;
    flex-direction: row;
}

.row3col div.field {
    width: calc(100% / 3);
}

.withscroll hr {
    border: none;
    background-color: #E0E0E0;
    height: 2px;
    margin-bottom: 2.1875rem;
}

.withscroll .cta {
    margin-bottom: 2rem;
    max-width: 100%;
    padding: 9px 15px;
}

.withscroll .cta p {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}

.drugdictionary {
    margin-top: 4.8125rem;
    margin-bottom: 2.5rem;
}

.drugdictionary a, .drugnotes p {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
}

.drugnotes {
    margin-bottom: 4.75rem;
}

.drugnotes div {
    font-weight: 500;
    font-size: 13px;
    line-height: 11px;
}

.drugnotes p {
    margin-top: 0.5rem;
    max-width: 97%;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
}

.drugsfound .title {
    color: #4E4E4E;
    margin-bottom: 0.5625rem !important;
    position: relative;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.drugrow {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
}

.drugrow > div:first-child {
    width: 4.2rem;
    margin-right: 1rem;
    float: left;
}

.drugrow div:nth-child(2), .drugschart .list div {
    font-weight: normal;
    padding-right: 10px;
}

.drugrow.martop {
    margin-top: 2rem;
}

.drugslist {
    margin-bottom: 1.875rem;
}

.drugschart {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.drugschart .chart {
    margin-right: 3.75rem;
}

.drugsfound .noteworthy {
    color: #e44f4fff;
}

.sampleviewback {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sampleviewback svg {
    margin-right: 1.25rem;
    cursor: pointer;
}

.nodrugs {
    font-family: 'Inter', sans-serif;
    color: #000000;
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.125rem;
}

.drugInfoBox {
    width: 135px;
    background: #F4F4F4;
    border-radius: 3.99513px;
    padding: 0.2rem 0.4rem;
}

.drugInfoBox p {
    text-align: center;
    color: #4E4E4E;
    font-weight: 500;
    font-size: 8.12734px;
    line-height: 11px;
}

.postNotes, .postNotes P {
    text-align: justify;
    font-weight: bold;
    font-size: 8px;
    line-height: 140%;
}

.withtoggle .rowcontent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footerbanner {
    padding: 0.9375rem 1.125rem;
    background: #FFFFFF;
    box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.05);
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #2C2A2E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    right: 1.9rem;
    left: 6.6rem;
}

.samples {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.footerbanner .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lowopac .rowcontent {
    opacity: 0.6;
}

.lowopac .rowcontent div.td:nth-of-type(2) {
    font-weight: bold;
}

.receiptsamples .box {
    margin-top: 1.375rem;
    padding-bottom: 0;
}

.receiptsamples .box .boxrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.75rem;
}

.receiptsamples .box .boxsample {
    display: flex;
    flex-direction: column;
}

.receiptsamples .box .boxsample:not(:first-child) {
    margin-left: 1rem;
}

.receiptsamples .box .sampleidlabel {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: #4E4E4E;
}

.receiptsamples .box .sampleid {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #000000;
}

.beside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.875rem;
}

.beside svg {
    margin-right: 0.875rem;
}

.beside label.label {
    margin: 0;
}

.receiptsamples div.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.box .shippingheader{
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 18px;
    margin-bottom: 36px;
    font-weight: 700;


}

.box .shippingheader .shippingtitle{
   color: #7f7f7f;
   width:"205px"

}
.sampleview .content {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
}

.sampleview .sampleviewhead {
    padding: 2.125rem 1.9375rem 0 2.125rem;
    background-color: var(--white);
    height: 8.5rem;
}

.sampleviewheadtop {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sampleviewheadtop .buttoncontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.sampleviewheadtop .buttoncontainer button:not(:last-child) {
    margin-right: 0.5rem;
}

.sampleviewhead hr {
    border: none;
    height: 1.5px;
    background-color: #E0E0E0;
    margin-top: 1.4375rem;
    margin-bottom: 0.875rem;
}

.sampleviewhead .summary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.sampleviewhead .summary .date {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #4E4E4E;
    display: flex;
    align-items: center;
}

.sampleviewcontent {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    height: calc(100% - 15.5rem);
}

.sampleviewcontent .title {
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    color: #000000;
}

.sampleviewcontent .col {
    background: #FFFFFF;
    border-radius: 0.5rem;
    padding: 1.4375rem;
    padding-right: 4px;
    height: 100%;
}

.sampleviewcontent .left {
    width: 80%;
    margin-right: 1.4375rem;
}

.sampleviewcontent .right {
    width: 20%;
}

.withscroll {
    overflow-y: auto;
    padding-right: 1.4375rem;
    height: 85%;
}

.right .withscroll {
    height: 90%;
}

.withscroll::-webkit-scrollbar, .sampleviewcontent .col::-webkit-scrollbar {
    width: 3.64px;
}

.withscroll::-webkit-scrollbar-track, .sampleviewcontent .col::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
}

.withscroll::-webkit-scrollbar-thumb, .sampleviewcontent .col::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.row3col {
    display: flex;
    flex-direction: row;
}

.row3col div.field {
    width: calc(100% / 3);
}

.withscroll hr {
    border: none;
    background-color: #E0E0E0;
    height: 2px;
    margin-bottom: 2.1875rem;
}

.withscroll .cta {
    margin-bottom: 2rem;
    max-width: 100%;
    padding: 9px 15px;
}

.withscroll .cta p {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}

.drugdictionary {
    margin-top: 4.8125rem;
    margin-bottom: 2.5rem;
}

.drugdictionary a, .drugnotes p {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
}

.drugnotes {
    margin-bottom: 1.75rem;
}

.drugnotes div {
    font-weight: 500;
    font-size: 13px;
    line-height: 11px;
}

.drugnotes p {
    margin-top: 0.5rem;
    max-width: 97%;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
}

.drugsfound .title {
    color: #4E4E4E;
    margin-bottom: 0.5625rem !important;
    position: relative;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
}

.drugrow {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
}

.drugrow > div:first-child {
    width: 4.2rem;
    margin-right: 1rem;
    float: left;

}



.no-bold {
    font-weight: normal !important;
}

.drugrow.martop {
    margin-top: 2rem;
}

.drugslist {
    margin-bottom: 1.875rem;
}

.drugschart {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.drugschart .chart {
    margin-right: 3.75rem;
}

.noteworthy {
    color: #e44f4fff;
}

.sampleviewback {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sampleviewback svg {
    margin-right: 1.25rem;
    cursor: pointer;
}

.nodrugs {
    font-family: 'Inter', sans-serif;
    color: #000000;
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.125rem;
}

.drugInfoBox {
    width: 135px;
    background: #F4F4F4;
    border-radius: 3.99513px;
    padding: 0.2rem 0.4rem;
}

.drugInfoBox p {
    text-align: center;
    color: #4E4E4E;
    font-weight: 500;
    font-size: 8.12734px;
    line-height: 11px;
}

.postNotes, .postNotes P {
    text-align: justify;
    font-weight: bold;
    font-size: 8px;
    line-height: 140%;
}

.terms-of-service {
    padding: 35px 36px 35px 32px;
    border-radius: 8px;

}
.terms-of-service .tc-content{
    display: flex;

}
.technology-section {
    padding: 35px 17px 34px 32px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.technology-section .technology-title, .technology-section .drugs-header {
    display: flex;
    justify-content: space-between;
}

.technology-section .info-link {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #309A9F;
}

.technology-section .info-link a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.technology-section .technology-title .field {
    margin-bottom: auto;
}

.drugrow .list {
    width: auto !important;
    font-weight: bold;
}

.terms-of-service:hover {
    background: #F4F4F4;
}

.terms-of-service {
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;

}
.terms-of-service .tc-content{
    display: flex;

}

.terms-of-service .text {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;

}

.terms-of-service .title {
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;

}

.drugsfound .drugs-header .text {
    font-weight: 500;
    font-size: 13px;

}

.bordered {
    border: 2px solid #E0E0E0;
    border-radius: 8px;
}
.sharingresults .checkbox {
    margin-bottom: 0.75rem;
}

.sharingresults input.other {
    width: 27.875rem;
}

.box .analysis {
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: #4E4E4E;
    margin-bottom: 0.375rem;
}

.sharingresults .warning {
    padding: 20px;
    margin-bottom: 38px;
    background: #E4F5F6;
    border: 1px solid #309A9F;
    display: flex;
}

.sharingresults .warning svg {
    margin-right: 12px;
}
.courierroutes {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
}

.courierroutes .topbar {
    height: 4.125rem;
    background-color: #044554;
    display: flex;
    align-items: center;
    justify-content: center;
}

.courierroutes .crcontent {
    width: 23rem;
    margin: auto;
    max-width: 100%;
    z-index: 10;
}

.courierroutes .crheader {
    height: 10.5rem;
    display: flex;
    align-items: center;
}

.courierroutes .crheader h1 {
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 2.5625rem;
    color: #044554;
    margin-bottom: 0.625rem;
}

.courierroutes .crheader .date {
    font-weight: 600;
    font-size: 1.0625rem;
    line-height: 1.125rem;
    color: #000000;
}

.courierroutes .locations {
    margin-bottom: 70px;
}

.courierroutes .locations h2 {
    font-weight: bold;
    font-size: 1.1875rem;
    line-height: 1.4375rem;
    color: #000000;
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
}

.courierroutes .crfooter {
    margin-top: 6.6875rem;
    padding: 2rem 7.1875rem 3.8125rem 7.1875rem;
    background: #F4F4F4;
}

.courierroutes .crfooter p {
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: #4E4E4E;
}

.courierroutes .crfooter .contact {
    display: flex;
    align-items: center;
}

.courierroutes .crfooter .contact svg {
    margin-right: 6px;
}

.courierroutes .notes h4 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 13px;
}

.courierroutes .notes p {
    padding: 20px 18px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    white-space: break-spaces;
}

@media screen and (max-width: 760px) {
    .courierroutes .crcontent {
        width: auto;
    }
    .courierroutes .crfooter {
        padding: 2rem;
    }
}
.reportdata .content-full .full-content {
    width: 69rem;
}
.field h6 {
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    color: #000000;
    margin-bottom: 2.0625rem;
    margin-top: 60px;
}

.receiptsamples hr {
    width: 100%;
}

.coldata {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.field .details {
    margin-bottom: 2.8125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.w80 {
    width: 65%;
}

.field .details:after {
    content: '';
    display: block;
    clear: both;
}

.field .details label {
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #3A3A3A;
    margin-bottom: 0.8125rem;
    display: block;
}

.field .details div.weight {
    margin-right: 1.5rem;
    float: left;
}

.field .details input {
    max-width: 8rem;
}

.field .details input:read-only {
    outline: none;
}

.field .details div.sign {
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    position: absolute;
}

div.signleft {
    left: -70%;
}

div.signright {
    right: -70%;
}

.field .details div.input {
    position: relative;
    width: 6.875rem;
    display: flex;
    align-items: center;
}

.field .details p {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #000000;
    position: absolute;
    right: 0;
    top: 0;
}

/* Chrome, Safari, Edge, Opera */
.input input::-webkit-outer-spin-button,
.input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input input[type=number] {
  -moz-appearance: textfield;
}


.details .winp {
    position: relative;
}

.details.liquid {
    opacity: 0.6;
}

.drugfoundtable {
    border-collapse: collapse;
    box-sizing: border-box;
    width: 100%;
    margin-right: 2px;
    margin-bottom: 2px;
}

.drugfoundtable th {
    border: 1px solid #747474;
    box-sizing: border-box;
    background: #373535;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: #FFFFFF;
    padding: 0.5rem 0 0.5rem 0.5rem;
    text-align: left;
}

.drugfoundtable .rowindex {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #7F7F7F;
    text-align: center;
}

.drugfoundtable td {
    padding: 0 0.5625rem;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
}

.drugfoundtable td.entry {
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #373535;
    position: relative;
}

.drugfoundtable input.entryinput {
    border: none;
    background: none;
    padding: 0;
    height: 2.9375rem;
    outline: none;
    width: 100%;

    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #373535;
}

.drugfoundtable select {
    position: absolute;
    width: 50%;
    right: 0;
    padding: 4px;
    height: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;  
    outline: none;
}

input[type=number]:read-only {
    border-bottom: none;
}

.selectarea.whiteback .select {
    background: none;
    border-bottom: none;
}

.selectarea.whiteback .select input[type=text] {
    background: none;
    width: 100%;
}

.selectarea.mini {
    width: 26rem;
}


.tableContainer {
    overflow: auto;
    width: 100%;
}

.tableContainer::-webkit-scrollbar {
    width: 3.64px;
    height: 5px;
}

.tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 20px;
}

.tableContainer::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.tableContainer td .tcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-bar {
    position: fixed;
    box-shadow: 0 -4px 20px 0 #00000026;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    height: 50px;
    padding: 13px;
}

.receiptsamples div.buttons {
    display: flex;
    flex-direction: row;
    margin: auto;
    align-items: center;
    width: 71rem;
}

.receiptsamples {
    margin-bottom: 120px;
}

.receiptsamples input {
    width: 26rem;
}

.btn4 {
    background: var(--white);
    color: #309A9F;
    border: 2px solid #309A9F !important;
}

.form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-header .btn {
    height: 50px;
}
.add-more{
    margin-top: 39px;
}
.long-margin{
    margin-top: 85px !important;
}
.analysis-technology-details h6{
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;

}
/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font-size: 15px;
    font-weight: 700;
    height: 56px;
    align-content: center;
}


.accordion__button:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 36px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    float: inline-end;
    color: #cdcdcd;
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
    transform: rotate(-135deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.full-width{width: 100%}
.accordion .field{
    margin-bottom: 29px;
}

.box.downloadbox {
    padding: 45px 35px;
}

.downloadbox .download {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.downloadbox .download .firstsection {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
}

.downloadbox .download .firstsection svg {
    margin-right: 30px;
}

.downloadbox .download .firstsection h3 {
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 4px;
}

.downloadbox .download .firstsection h4 {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    margin: 0;
}

.downloadbanner {
    left: 0;
    right: 0;
}

.downloadbanner .buttoncontainer {
    width: 32.875rem;
    margin: 0 auto;
}

.dlload {
    animation: spin 1s linear;
    animation-iteration-count: infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
:root {
    --blue: #044554;
    --blue2: #309A9F;
    --blue3: #77CCD0;
    --blue4: #E4F5F6;
    --green: #AB9969;
    --green2: #ABC897;
    --red: #E44F4F;
    --red2: #EE6C82;
    --red3: #FCCAAC;
    --red4: #FBE5D7;
    --orange: #F4D166;
    --gray: #4E4E4E;
    --gray2: #7F7F7F;
    --gray3: #C4C4C4;
    --gray4: #F5F5F5;
    --black: #373535;
    --white: #ffffff;
}

body {
    margin: 0;
    padding: 0;
}

h1 {
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.3125rem;
    color: #000000;
}

h2 {
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 1.625rem;
    line-height: 1.875rem;
    color: #000000;
}

.subhead {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    color: #000000;
}

.text-body {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
}

.btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    border: none;
    min-width: 7rem;
    padding: 0.9375rem 1.125rem;
    border: 2px solid #4B4B4B;
    border-radius: 0.3125rem;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    text-decoration: none;
}

.btn1 {
    background: var(--black);
    color: var(--white);
}


.btn2 {
    background: var(--white);
    color: #4B4B4B;
}

button:disabled,
button[disabled]{
    opacity: 0.4;
    border-radius: 5px;
    cursor: not-allowed;
}

.btn3 {
    background: #F4F4F4;
    border-radius: 1.875rem;
    font-size: 1rem;
    line-height: 2.5rem;
    color: var(--black);
    border: none;
    padding: 0 1.3125rem;
}

.btn-clear {
    cursor: pointer;
    background-color: transparent;
    border: none; 
}

.banner {
    box-shadow: 0px -2px 1.875rem rgba(0, 0, 0, 0.05);
    padding: 0.8125rem;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #2C2A2E;
}

input[type=text], input[type=password], input[type=email], input[type=number] {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.1875rem;
    padding-right: 1.1875rem;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #5E5E5E;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    background-color: var(--gray4);
    box-sizing: border-box;
}

input[type=text]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;    
    color: rgba(0, 0, 0, 0.5);
}

input[type=text]:-ms-input-placeholder { /* 'Inter', sans-serifnet Explorer 10-11 */
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;    
    color: rgba(0, 0, 0, 0.5);
}

input[type=text]::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;    
    color: rgba(0, 0, 0, 0.5);
}

input[type=text].hidden {
    opacity: 0;
    width: 100%;
    height: 0;
    border: none;
    padding: 0 0 0.5px 0;
    margin: 0;
    outline: none;
    pointer-events: none;
    display: block;
}

.spzero {
    padding: 0;
    margin: 0;
}

a {
    color: var(--blue2);
}

.mr-1 {
    margin-right: 1rem;
}

.ml-1 {
    margin-left: 1rem;
}

.mr-seven {
    margin-right: 7px;
}

.mr-3 {
    margin-right: 3rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mt-1 {
    margin-top: 1rem;
}

.pb-8 {
    padding-bottom: 8rem;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.field textarea {
    height: 10.5rem;
    overflow-y: auto;
    border-radius: 4px;
    background-color: var(--gray4);
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #5E5E5E;
    font-family: 'Inter', sans-serif;
    color: #000000;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.1875rem;
    box-sizing: border-box;
    resize: none;
    font-size: 0.9375rem;
    line-height: 1.125rem;
}

.ml-auto {
    margin-left: auto;
}

.tag {
    display: inline-block;
    background: #F4F4F4;
    border-radius: 1.625rem;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    margin-right: 3px;
    margin-bottom: 3px;
    padding: 0.625rem 0.875rem;
    text-align: center;
}

.box {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: 1.8125rem 1.125rem;
}

.box .boxheader {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    padding-bottom: 1.125rem;
    margin-bottom: 1.875rem;
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    align-items: flex-end;
}

.box .boxheader svg {
    margin-right: 5px;
}

.box.CAMH {
    background: #E4F5F6;
    border: 1px solid #309A9F;
}


.box.CAMH .boxheader {
    border-bottom: 1px solid #309A9F;
}


.box.SMH {
    background: #FBE5D7;
    border: 1px solid #EE6C82;
}

.box.SMH .boxheader {
    border-bottom: 1px solid #EE6C82;
}

.box.DCU {
    background: #E4DCE8;
    border: 1px solid #857590;

}

.box.DCU .boxheader{
    border-bottom: 1px solid #857590;

}

.box.DAS {
    background: #E3EFDA;
    border: 1px solid #A5C986;

}

.box.DAS .boxheader {
    border-bottom: 1px solid #A5C986;

}

.mb-2 {
    margin-bottom: 2rem;
}

.cta {
    background: #F4F4F4;
    border-radius: 8px;
    padding: 0.9375rem;
    border-left: 0.5625rem solid #044554;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.notAnalyzed {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #4E4E4E;
}

.notAnalyzed p {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    margin-top: 8px;
}

.pal {
    padding: 1rem;
}

.dfrow {
    display: flex;
    flex-direction: row;
}

.dfrow .mg {
    margin-left: 3px;
    margin-right: 10px;
}
h2 {
    line-height: 2.85rem;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #373535;
}

.entry-title {
    font-size: 48px;
    font-family: 'Asap', sans-serif;
    font-weight: 700;
    color: #044554;
}

.entry-content, footer {
    font-family: 'Roboto', sans-serif;
    color: #373535
}

#site-title {
    font-family: 'Asap', sans-serif;
    font-weight: 700;
    font-size: 36px;
    padding-right: 50px;
    width: 100%;
    text-align: right
}

#site-title a {
    text-decoration: none;
    color: #044554;
    line-height: 1.1;
}

#site-title a:hover {
    text-decoration: underline;
}

#branding {
    background-image: url('https://drugchecking.community/wp-content/themes/drugcheck/images/hero-bg.jpg');
    background-size: cover;
    background-position: right;
    padding: 50px 0 30px;
    text-align: right;
}

.max-w {
    max-width: 920px;
    margin: 0 auto;
}


ul.social {
    float: left;
    margin-left: 20px;
}.footer-ctas {margin-top:20px;}
.footer-ctas a, .footer-ctas li {float:left; list-style: none}
.footer-content {max-width:340px;}
ul.social {float:left;margin-left:20px;}
ul.social li a {height: 33px;width: 33px;margin: 1px 5px 0;color: #333333;transition: color .2s;display:inline-block; float:left;}
ul.social li a svg {width:100%;height:100%;fill:#373535;}
.footer-logo {margin-top:50px;}
.footer-logo svg{width:132px;margin-left: 0;}

.contact-info {
    margin-top: 60px;
}

.post-meta {margin-top:30px;}
.post-meta a{margin-top:30px;color:#373535;}
.download-cta {font-weight:bold;}
.social-media-icons {display:block;}
.social-media-icons a { width: 33px; height: 33px; display: inline-block; margin-right: 7px; margin-top: 3px; position: relative; top: 8px; }
.social-media-icons a svg{width:100%;height:100%;fill: #373535;}


.entry-content li:last-child {margin-bottom:0;}
div.cap p:first-child {margin-top: 0;}
div.cap {margin-top:30px;}
.entry-content img {width:100%;height:auto;display:block;}
.entry-content img.border {border:2px solid #d4d4d4;}
.entry-content .tableauPlaceholder {margin-top:30px;border:2px solid #d4d4d4;width:100% !important;}
.entry-content .desktop-only {display:none;}
.entry-content .tableauPlaceholder + script + p {margin-top:80px;}
.table-wrap {margin-top:80px;margin-left: -20px; width:calc(100% + 40px);overflow-x:auto;}
.table-pad {padding:0 20px;}
.table-wrap .col {float:left;padding-right:40px;}
.table-wrap .col-4 {padding-right:0;}
.table-row {padding:30px 0; width:100%;min-width:675px;font-size:16px;line-height:1.4;}
.table-row:nth-child(odd){background:#FAEFE8;}
.table-header {padding:40px 0;font-size:18px;font-weight:700;color:#044554;}
.col-1 {width:150px;}
.col-2 {width:130px;}
.col-3 {width:100px;height:1px;}
.col-4 {width:360px;}
.note-icon {width:30px;height:30px;background-image:url('https://drugchecking.community/wp-content/themes/drugcheck/images/note-icon.png');background-size:30px;background-repeat:no-repeat;}
.col .note-icon {text-indent:-10000px;margin-left:20px;}
.entry-content .col span {padding:0;}


.post-ex {margin-top:60px;}
.post-ex time {font-size:24px;font-weight:bold;}
.post-ex h2 a:hover {text-decoration:underline;}
.post-ex p {margin-top:30px;color:#044554;}
.post-ex a {margin-top:30px;}
.sticky-pin {position:absolute;width:40px;height:40px;margin-top:-22px;right:15px;border-radius:100%;top:0;background-image:url(https://drugchecking.community/wp-content/themes/drugcheck/images/pin-icon.png);background-size:40px;}

footer {
    padding: 50px 26px 50px 26px;
    background: #f4f4f4;
    margin-top: 50px;
}

footer p {
    margin-bottom: 10px;
}
footer p:last-child {margin-bottom:0;}
.footer-ctas {margin-top:20px; display: flex; align-items: center}
.cta2 {
    border-radius: 5px;
    background-color: #044554;
    text-decoration: none;
    color: #fff;
    padding: 7px 10px;
    display: inline-block;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
}
.footer-ctas a, .footer-ctas li {float:left;}
.footer-content {max-width:340px;}
ul.social {float:left;margin-left:20px;}
ul.social li a {height: 33px;width: 33px;margin: 1px 5px 0;color: #333333;transition: color .2s;display:inline-block; float:left;}
ul.social li a svg {width:100%;height:100%;fill:#373535;}
.footer-logo {margin-top:50px;}
.footer-logo svg{width:132px;margin-left: 0;}
/* .footer-logo-header{margin-bottom: 10px;} */

.footer-logos { display: flex; flex-wrap: wrap; grid-gap: 20px; gap: 20px; align-items: baseline;    padding-top: 20px;}


/* .cdpe-logo{padding-top: 20px}  */


.contact-info {margin-top:60px;}
@media screen and (min-width: 400px) {

    .post-bg {padding:30px 0;margin-left:-30px;width:calc(100% + 60px);}
    .entry-content span {padding:30px;}
    .table-wrap { margin-left: -30px; width:calc(100% + 60px);}
    .table-pad {padding:0 30px;}
    .sticky-pin {right:25px;}
    .manual-pager-wrap {font-size:18px;}
    .manual-pager .next:before {top:2px;}
    .manual-pager .prev:before {top:2px;}
    nav li {margin-right:9px;}
    nav li:last-child {margin-right:0;}
    footer {font-size:18px;}
    .table-row {font-size:18px;}
    .table-header {font-size:24px;}
    .col-1 {width: 150px; padding-right: 0 !important;}
    .col .note-icon {margin-left:25px;}

}

@media screen and (min-width: 853px) {
    .entry-title {
        font-size: 30px;

    }

    #branding {
        padding-top: 111px;
        padding-bottom: 60px;
    }

    .footer-logo svg {
        width: 132px;
        margin-bottom: 50px;
    }

    .footer-logos {
        display: flex;
        flex-direction: column;
        padding-top: 15px;
        align-items: baseline;
    }

    .footer-logo svg:first-child{margin:0}


    .download-cta,time,.share-cta {display:inline-block;}
    .download-cta + .share-cta:before,
    time + .share-cta:before,
    time + .download-cta:before {content:" | ";padding:0 5px;font-weight:300;}
    .download-cta + .share-cta,
    time + .share-cta,
    time + .download-cta {margin-top:0;}
    .social-media-icons {display:inline-block;}
    .social-media-icons a {display: inline-block; margin-right: 0; margin-left:7px; margin-top:0;}
    .entry-content .desktop-only {display:block;}
    .entry-content .mobile-image {display:none;}

    .post-bg {padding: 40px 0;border-radius:5px;}
    .sticky-pin {left:-24px;right:auto;transform: rotate(-30deg);}

    .col-4 {width:380px;}

    .footer-content {width:50%;     padding-right: 35px;}
    .footer-layout {display:flex;justify-content: space-between;}
    .footer-logo { margin-top: 0; align-content: center;
        /* width: 50%;  */
        display: flex; justify-content: start; align-items: start; flex-direction:column;
        position: relative;
        padding-left: 35px;
        /* top: 16px; */
    }


}

@media screen and (min-width: 800px) {
    .col-4 {width:430px;}
}

@media screen and (min-width: 920px) {

    /* mobile menu */
    .js #menu {display: block;margin-top:0;}
    .js #menu {position: relative; width: 100%; background: #fff; border: 0; padding: 0; left: -10px;right:0;}
    .js #toggle {display: none;}
    #toggle {display: none;}
    #menu {position:relative;}
    nav li {float:left;}
    nav li.current-menu-item a {color:#fff;background-color:#044554;}
    nav li.current-menu-item a:hover {text-decoration:none;}
    nav li a {color:#044554;}
    .menu-title{display:none;}
    .footer-logo svg:nth-child(2){margin-bottom:0}
    .footer-logos{flex-direction: row; flex-wrap: unset; grid-gap: unset; gap: unset; align-items: end;}


    .cdpe-logo:nth-child(2){margin-left: 35px}
    .cdpe-logo:first-child{margin: 0;}

}

@media screen and (min-width: 940px) {
    .col-4 {width:530px;}
}

p a, ul a, ol a {
    color: #EE6C82;
    font-weight: 700;
}

@media only screen and (max-width: 853px) {
    .service-user-inner {
        padding: 26px;
        margin: auto;
    }

}
.sharingresults .checkbox {
    margin-bottom: 0.75rem;
}

.sharingresults input.other {
    width: 27.875rem;
}

.box .analysis {
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: #4E4E4E;
    margin-bottom: 0.375rem;
}

.sharingresults .warning {
    padding: 20px;
    margin-bottom: 38px;
    background: #E4F5F6;
    border: 1px solid #309A9F;
    display: flex;
}

.sharingresults .warning svg {
    margin-right: 12px;
}

.entry-content {
    padding-top: 56px;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
}

.entry-content .field {
    margin-bottom: 31px;
}

.full {
    width: 100%;
}

.has-error input {
    border-bottom: 2px solid red;
}

.has-error .error-message {
    color: red;
    padding-top: 6px;
}

.sample-header {
    z-index: 1000;
    height: 86px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.1px);
    -webkit-backdrop-filter: blur(11.1px);
}

.sample-header .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    padding: 12px;
}

.sample-header .sample-id {
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    color: #373535;
    font-family: 'Asap', sans-serif;
    margin: auto;
    width: 75%;
}

.result-page-content {
    margin-top: 120px;
    margin-left: 40px;
    width: 835px;
    background: white;
    border-radius: 8px;

    border: solid #E0E0E0;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.25);
}

.service-user-inner {
    width: 450px;
    margin: auto;
}

.sample-result .page {
    position: relative;
    top: -200px;
}

.sample-result .page .sample-view-content {
    padding: 21px 21px 0;
}

.sample-result .page .sample-view-content .row3col {
    justify-content: space-between;
}

.sample-result .page .sample-view-content .row3col div.field {
    width: calc(100% / 2);
    margin-bottom: 0;
}

.sample-result .page .sample-view-content .sample-section {
    border: 2px solid #E0E0E0;
    border-radius: 8px;
    padding: 23px;
    margin-bottom: 23px;
}


.section-devider {
    margin-bottom: 36px;
}


.noteworthy {
    color: #e44f4fff;
}

.btn.access-another-btn {
    width: 180px;
}

.service-user-inner .button {
    padding-top: 73px;
}

.sample-section .title h3 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 46px;
}

.cta {
    background: #F4F4F4;
    border-radius: 8px;
    padding: 0.9375rem;
    border-left: 0.5625rem solid #044554;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.cta p {
    margin: 0;
}

.accordion {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 36px;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.sample-view-content .terms-of-service {
    margin-bottom: 0;
}

.mt-2 {
    margin-top: 29px !important;
}

.h-pad {
    display: flex;
    justify-content: center;
}

.thumb-down {
    padding-left: 16px;
}

.feedback-desc {
    width: 419px;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
}

.pb-1 {
    padding-bottom: 13px;
}

.hide-desktop {
    display: none;
}

.sample-result hr {
    border: 1px solid rgba(0, 0, 0, 0.1);

}

.feedback {
    width: 700px;
    display: flex;
    justify-content: space-between;
    justify-items: center
}

.technology-title, .drugs-header {
    display: flex;
    justify-content: space-between;
}

.info-link a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #309A9F;
}

.technology-title .technology-title .field {
    margin-bottom: auto;
}

.mobile-only {
    display: none;
}

@media only screen and (max-width: 853px) {
    .fixed-header {
        top: 0;
    }

    .mobile-only {
        display: block;
    }

    .full-content {
        margin: 20px !important;
        width: 90% !important;
    }

    form.sharingresults {
        margin-top: 38px;
    }

    form.sharingresults .checkbox {
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 16px 19px;
        margin-top: 4px;
    }

    header #branding {
        padding: 0;
        height: 281px;
        background-size: auto;
        background-position: -134px 56px;
    }

    .hide-mobile {
        display: none;
    }

    .sample-header .button .btn {
        width: 38px;
        height: 38px;
        min-width: 38px;
        padding: 0;
        margin: 2px 10px 0 0;
    }

    .sample-header .sample-id {
        text-align: center;
        font-weight: 700;
        font-size: 26px;
        color: #373535;
        font-family: 'Asap', sans-serif;
        margin: auto;
        width: 80%;
    }

    .sample-header {
        height: 66px;
    }
    .sample-result .page .sample-view-content .row3col div.field {
        width: 100%;
        margin-bottom: 0;
    }
    .withscroll {
        overflow-y: auto;
        padding-right: 10px;
        height: 85%;
    }
    .result-page-content {
        margin-top: 136px;
        margin-left: auto;
        width: 100%;
        background: none;
        border-radius: 0;
        border: none;
        box-shadow: none;
    }

    .sample-result .page {
        position: relative;
        top: -341px;
    }

    .sample-result .page .sample-view-content .sample-section {
        background: white;
    }

    .sample-result .page .sample-view-content .sample-section .row3col {
        display: inline;
    }

    .sample-result .technology-title {
        display: inline;
    }

    .info-link a {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f4f4f4;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 24px;

    }
    .tc-content .info-link a {
        background: none;
        align-items: start;
        padding: 0;
    }

    .show-mobile {
        display: block;
    }

    .nodrugs, .drugrow {
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 16px 19px;
        margin-top: 4px;
        flex-direction: column;
    }

    .w-100 {
        width: 100% !important;
    }

    .nodrugs-mobile {

        font-family: 'Inter', sans-serif;
        color: #000000;
        margin-bottom: 2rem;
        font-weight: 700;
        font-size: .9375rem;
        line-height: 1.125rem;
    }

    .drugs-header .text {
        margin-bottom: 7px;
    }

    .drug-name {
        padding: 0;
        width: 100%;
        margin: 5px 0 0 0;
        float: left;
    }
    .drugslist .list{
        padding-bottom: 130px;
    }
    .drug-name .padding-drug{
        padding: 10px;
    }

    .drugrow .quantity, .drugrow .percentage, .drugrow .list {
        float: left;
    }

    .terms-of-service {
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin: 20px;
    }
    .terms-of-service .tc-content{
        display: flex;

    }
    .feedback {
        width: 100%;
        display: block;

        text-align: center;

    }
    .feedback-container{
        background: #f4f4f4;
        padding: 20px;
        margin: -23px;
        border-radius: 0 0 8px 8px;
    }
    .feedback-desc {
        width: 100%;
        font-size: 17px;

    }
    .service-user-inner{
        width: initial;
    }

}

.sample-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px 0;
    background: #f4f4f4;
    margin-top: 5px;
}

