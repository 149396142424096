

.notification .icon {
    margin-left: 1.0625rem;
}

.notification .content {
    margin-left: 1.0625rem;
    color: #FFFFFF;
    width: 15.625rem;
    flex-grow: 1;

}

.notification .content .message {
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.375rem;
}

.notification .content .status {
    font-family: 'Asap', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.1875rem;
}

.notification .view {
    font-family: 'Asap', sans-serif;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: #FFFFFF;

    border-left: 1.11935px solid #FFFFFF;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    height: 2rem;

    display: flex;
    align-items: center;
}

@keyframes notificationFadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    100% {
        opacity: 1;
        display: flex;
    }
}

@keyframes notificationFadeOut {
    0% {
        opacity: 1;
        display: flex;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

.notification.hide {
    transform: translateY(100%);
    opacity: 0;
}

.notification.show {
    transform: translateY(0);
    opacity: 1;
}



.notifications-container {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.notification {
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.5s ease, opacity 0.5s ease;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


